import React from 'react'
import { graphql} from 'gatsby'
import get from 'lodash/get'

import Seo from '../components/seo'
import Layout from '../components/layout'
import Hero from '../components/hero'
import ItemPreview from '../components/itemPreview/item-preview'

class CitiesPage extends React.Component {
  render() {
    const allCities = get(this, 'props.data.allContentfulCity.nodes')
    // const neighborhoods = get(this, 'props.data.allContentfulNeighborhood.nodes')
    

    return (
      <Layout location={this.props.location}>
        <Seo title="Cities" />
        <Hero title="Cities" />

        <ItemPreview items={allCities} slugName="city" />

         
      </Layout>
    )
  }
}

export default CitiesPage

export const pageQuery = graphql`
  query CitiesPageQuery {
    allContentfulCity {
      nodes {
        name
        slug
      }
    }
    allContentfulNeighborhood {
      nodes {
        name
        slug
        cities {
          name
        }
      }
    }
  }
`
